import { MrfiktivHttpClientProvider } from "@/services/mrfiktiv/mrfiktiv-http-client.provider";
import { Ai } from "../v1/Ai";

/**
 * Communicate with ai API
 */
class AiService {
  mrfiktivProxy: Ai;

  /**
   * Construct service
   */
  constructor(mrfiktivHttpClientProvider: MrfiktivHttpClientProvider) {
    this.mrfiktivProxy = new Ai(mrfiktivHttpClientProvider.client());
  }

  /**
   * Get the VIN from the registration image
   */
  async getRegistrationResult(partnerId: string, imageId: string) {
    return (await this.mrfiktivProxy.aiControllerGet(partnerId, imageId)).data;
  }

  /**
   * Get the summary from the report
   */
  async getReportSummary(partnerId: string, reportId: string) {
    return (await this.mrfiktivProxy.aiControllerSummarize(partnerId, reportId)).data;
  }
}

/**
 * Communicate with ai API
 */
export default new AiService(new MrfiktivHttpClientProvider());
